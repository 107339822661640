import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import reducer from '../reducers';
import { composeWithDevTools } from 'redux-devtools-extension';

// Check for Redux dev tools in dev environment
// const appliedMiddleware = process && process.env.__DEV__ ? composeWithDevTools(applyMiddleware(thunk)) : applyMiddleware(thunk);

// preloadedState will be passed in by the plugin
export default preloadedState => {
  return createStore(reducer, preloadedState, composeWithDevTools(applyMiddleware(thunk)));
};
