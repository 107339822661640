import { FETCH_CHANGE_COUNTRY_DATA_SUCCESS, FETCH_CHANGE_COUNTRY_DATA_FAILURE } from '../constants';

const changeCountryData = (state = {}, action) => {
  switch (action.type) {
    case FETCH_CHANGE_COUNTRY_DATA_SUCCESS:

      return action.payload;
    
    case FETCH_CHANGE_COUNTRY_DATA_FAILURE:

      // TODO: there might be a better way for error handling
      // for now just return the previous state (empty object by default)
      return state;

    default:
      return state;
  }
};

export default changeCountryData;

