import { SELECT_PROGRAM_CODE, CLOSE_PROGRAMS_POPUP } from '../constants';

const selectedProgramCode = (state = '', action) => {
  switch (action.type) {
    case SELECT_PROGRAM_CODE:

      return action.code;
    
    case CLOSE_PROGRAMS_POPUP:

      return '';


    default:
      return state;
  }
};

export default selectedProgramCode;

