import { FETCH_FOOTER_DATA_SUCCESS, FETCH_FOOTER_DATA_FAILURE } from '../constants';

const footerData = (state = {}, action) => {
  switch (action.type) {
    case FETCH_FOOTER_DATA_SUCCESS:

      return action.payload;
    
    case FETCH_FOOTER_DATA_FAILURE:

      // TODO: there might be a better way for error handling
      // for now just return the previous state (empty object by default)
      return state;

    default:
      return state;
  }
};

export default footerData;

