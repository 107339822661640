import { combineReducers } from 'redux';
import serviceData from './serviceData';
import headerData from './headerData';
import footerData from './footerData';
import changeCountryData from './changeCountryData';
import selectedProgramCode from './selectedProgramCode';
import filteredProgramCodes from './filteredProgramCodes';
import isProgramPopUpOpen from './isProgramPopUpOpen';
import serverError from './serverError';


/*
** Config is an Object passed from Edge representing a bunch of environment variables
** Depending on the project configuration this may change but usually it includes the below
** and it is very unlikely that this reducer will need to touched at all since once is populated server side no further changes make sense

** - host: (string) The Current host (eg: 'https://ctdev.ef.com')
** - basePath: (string) The base Path (this includes subdomains eg: ef.com/sa/contact)
** - fullPath: (string) The full url (eg: ef.com/sa/contact/medina ). This is key for routing dynamic pages
** - servicePath: (string) The service returning the page Data for this url
** - countryCode: (string) The ISO code of the market (eg: Switzerland is "CH")
** - mc: (string) The Market code (eg: Switzerland is "CS")
** - eflang: (string) The lang used for markets that uses multiple languages (eg: TN market support lang "ar" and lang "fr")
** - nolang: (boolean) The flag used to reset the eflang selection. It can be either null or 1
*/


// The config and serverError reducers below are coming from .NET server via Edge and will never mutate
const config = (state = {}) => state;

export default combineReducers({ 
  serviceData,
  headerData,
  footerData,
  changeCountryData,
  serverError,
  filteredProgramCodes,
  programCode: selectedProgramCode,
  isPopUpOpen: isProgramPopUpOpen,
  config
});
