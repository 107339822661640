import { OPEN_PROGRAMS_POPUP, CLOSE_PROGRAMS_POPUP } from '../constants';

const selectedProgramCode = (state = false, action) => {
  switch (action.type) {
    case OPEN_PROGRAMS_POPUP:

      return true;
    
    case CLOSE_PROGRAMS_POPUP:

      return false;


    default:
      return state;
  }
};

export default selectedProgramCode;

