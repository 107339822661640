// **************************************************************
// Async actions
// **************************************************************

export const FETCH_PAGE_DATA_REQUEST        = 'FETCH_PAGE_DATA_REQUEST';
export const FETCH_PAGE_DATA_SUCCESS        = 'FETCH_PAGE_DATA_SUCCESS';
export const FETCH_PAGE_DATA_FAILURE        = 'FETCH_PAGE_DATA_FAILURE';

export const FETCH_HEADER_DATA_REQUEST        = 'FETCH_HEADER_DATA_REQUEST';
export const FETCH_HEADER_DATA_SUCCESS        = 'FETCH_HEADER_DATA_SUCCESS';
export const FETCH_HEADER_DATA_FAILURE        = 'FETCH_HEADER_DATA_FAILURE';

export const FETCH_FOOTER_DATA_REQUEST        = 'FETCH_FOOTER_DATA_REQUEST';
export const FETCH_FOOTER_DATA_SUCCESS        = 'FETCH_FOOTER_DATA_SUCCESS';
export const FETCH_FOOTER_DATA_FAILURE        = 'FETCH_FOOTER_DATA_FAILURE';

export const FETCH_CHANGE_COUNTRY_DATA_REQUEST = 'FETCH_CHANGE_COUNTRY_DATA_REQUEST';
export const FETCH_CHANGE_COUNTRY_DATA_SUCCESS = 'FETCH_CHANGE_COUNTRY_DATA_SUCCESS';
export const FETCH_CHANGE_COUNTRY_DATA_FAILURE = 'FETCH_CHANGE_COUNTRY_DATA_FAILURE';

export const OPEN_PROGRAMS_POPUP              = 'OPEN_PROGRAMS_POPUP';
export const CLOSE_PROGRAMS_POPUP             = 'CLOSE_PROGRAMS_POPUP';
export const SELECT_PROGRAM_CODE              = 'SELECT_PROGRAM_CODE';

export const SUBTILE_PROGRAM_SECTION_ID       = 'subtile-categories-wrapper';
export const LANGTILE_PROGRAM_SECTION_ID      = 'langtile-categories-wrapper';
export const AGE_PROGRAM_SECTION_ID           = 'age-categories-wrapper';
export const TOP_PAGE_ID                      = 'funneling-root';
export const POPUP_SLUG                       = '';
export const HOMEPAGE_SLUG                    = 'homepage';

