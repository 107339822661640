import { FETCH_PAGE_DATA_FAILURE } from '../constants';

const serviceError = (state = {}, action) => {
  switch (action.type) {    
    case FETCH_PAGE_DATA_FAILURE:
      return {error: `Error fetching ${action.url}`};

    default:
      return state;
  }
};

export default serviceError;

